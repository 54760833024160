<template>
  <div id="container">
    <!-- --- -->
    <!-- STEP 1 -->
    <!-- --- -->
    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>Step 1</ion-card-subtitle>
        <ion-card-title>Bill Information</ion-card-title>
        <ion-card-subtitle style="font-style: italic">In this section you tell the app the total you have been or expect to be billed</ion-card-subtitle>
      </ion-card-header>

      <!-- <ion-item>
        <ion-icon :icon="pin" slot="start"></ion-icon>
        <ion-label>ion-item in a card, icon left, button right</ion-label>
        <ion-button fill="outline" slot="end">View</ion-button>
      </ion-item> -->

      <ion-card-content>
        <ion-item>
          <ion-label position="stacked" for="totalPaid">Total Paid</ion-label>
          <ion-input inputmode="number" name="totalPaid" type="number" placeholder="0" v-model="totalPaid">$&nbsp;</ion-input>
        </ion-item>
        <ion-item class="noborder">
          <ion-label>Tip is Included (if applies)</ion-label>
          <ion-checkbox color="primary" @ionChange="tipIsIncluded = $event.target.checked" v-bind:checked="tipIsIncluded"></ion-checkbox>
        </ion-item>
        <div class="tip-panel" v-if="!tipIsIncluded">
          <ion-item class="noborder">
            <ion-segment class="noborder" mode="ios" @ionChange="tipTypeChanged($event)" v-bind:value="tipType">
              <ion-segment-button value="percentage">
                <ion-label>%</ion-label>
              </ion-segment-button>
              <ion-segment-button value="amount">
                <ion-label>$</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>
          <ion-item class="noborder align-right" v-if="tipType === 'percentage'">
            <ion-label position="fixed" for="tipPercentage">Percentage</ion-label>
            <ion-input inputmode="number" max="100" min="0" name="tipPercentage" type="number" v-model="tipPercentage"></ion-input>&nbsp;%
          </ion-item>
          <ion-item class="tipResult noborder align-right" v-if="tipType === 'percentage'">
            <ion-label position="fixed" for="totalTip">Total Tip</ion-label>
            <ion-input inputmode="number" name="totalTip" type="number" readonly v-model="tip"></ion-input>&nbsp;$
          </ion-item>
          <ion-item class="tipPercentage noborder align-right" v-if="tipType === 'amount'">
            <ion-label position="fixed" for="tipAmount">Amount</ion-label>
            <ion-input inputmode="number" name="tipAmount" placeholder="0" type="number" v-model="tipAmount"></ion-input>&nbsp;$
          </ion-item>
          <ion-item class="tipResult noborder align-right" v-if="tipType === 'amount'">
            <ion-label position="fixed" for="calculatedPercentage">Percentage</ion-label>
            <ion-input inputmode="number" name="calculatedPercentage" type="number" readonly v-model="calculatedPercentage"></ion-input>&nbsp;%
          </ion-item>
        </div>
        <ion-chip class="total-billed" color="primary">
          <ion-label
            >Total Billed: <span class="total-billed-strong">$ {{ totalBilled }}</span></ion-label
          >
        </ion-chip>
      </ion-card-content>
    </ion-card>

    <!-- --- -->
    <!-- STEP 2 -->
    <!-- --- -->
    <ion-card v-if="totalBilled > 0">
      <ion-card-header>
        <ion-card-subtitle>Step 2</ion-card-subtitle>
        <ion-card-title>Participants</ion-card-title>
        <ion-card-subtitle style="font-style: italic">In this section you specify the number (and name) of participants, and the cost of items assigned exclusively to themselves</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        <ion-item class="noborder">
          <ion-label position="stacked" for="numParticipants">Number of Partipants</ion-label>
          <!--{{ numParticipants > 1 ? "people" : "person" }}-->
          <div class="participantsSelector">
            <ion-button size="medium" class="add-remove-participant-button" color="primary" v-on:click="numParticipants > 0 ? numParticipants-- : ''"><ion-icon class="add-remove-participant-button-icon" :icon="personRemoveOutline"></ion-icon></ion-button>
            <ion-input name="numParticipants" class="add-remove-participant-button-input" disabled type="number" min="1" max="20" v-model="numParticipants"></ion-input>
            <!-- <ion-chip> <ion-label>{{ numParticipants }}</ion-label></ion-chip> -->
            <ion-button size="medium" class="add-remove-participant-button" color="primary" v-on:click="numParticipants < 20 ? numParticipants++ : ''"><ion-icon class="add-remove-participant-button-icon" :icon="personAddOutline"></ion-icon></ion-button>
          </div>
        </ion-item>
        <ion-card v-for="participant in numParticipants" v-bind:key="participant">
          <ion-card-header>
            <ion-card-subtitle class="participant-subtitle" @click="participantNameClick(participant - 1)"><ion-icon class="participant-icon" :icon="personOutline"></ion-icon>{{ participants[participant - 1].name }}<ion-icon class="participant-edit-icon" :icon="createOutline"></ion-icon></ion-card-subtitle>
          </ion-card-header>
          <!-- <ion-card-content style="margin-top: -20px"> -->
          <ion-card-content>
            <p class="participant-comment" v-if="participants[participant - 1].comment">{{ participants[participant - 1].comment }}</p>
            <ion-item style="margin-top: -10px">
              <ion-label position="stacked" for="participantSubtotal">Personal Items Subtotal:</ion-label>
              <ion-input inputmode="number" name="participantSubtotal" type="number" placeholder="0" v-on:ionChange="participantSubtotalChange" v-model="participants[participant - 1].subtotal">$&nbsp;</ion-input>
            </ion-item>
            <ion-chip class="participant-subtotal-chip" outline="true" color="primary">
              <ion-label
                >Subtotal: <span class="participant-subtotal-chip-strong">$&nbsp;{{ Number(participants[participant - 1].subtotal) }}</span></ion-label
              >
            </ion-chip>
          </ion-card-content>
        </ion-card>
        <ion-chip class="participants-subtotal-chip" outline="false" color="primary">
          <ion-label
            >Participants Subtotal: <span class="participants-subtotal-chip-strong">$&nbsp;{{ participantsSubTotal }}</span></ion-label
          >
        </ion-chip>
      </ion-card-content>
    </ion-card>
    <!-- --- -->
    <!-- STEP 2B -->
    <!-- --- -->
    <ion-card v-if="totalBilled > 0">
      <ion-card-header>
        <ion-card-subtitle>Step 2-B (Optional)</ion-card-subtitle>
        <ion-card-title>Shared Items</ion-card-title>
        <ion-card-subtitle style="font-style: italic">In this section you add items that are shared among more than one participant (do not add items that belong to a single person if you did so in the previous section)</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        <ion-card v-for="(item, index) in sharedItems" v-bind:key="index">
          <ion-card-content>
            <ion-item class="noborder">
              <ion-label position="stacked" for="sharedItemName">Item Name:</ion-label>
              <ion-input inputmode="text" name="sharedItemName" type="text" placeholder="French Fries" v-model="item.name"></ion-input>
            </ion-item>
            <ion-item class="noborder">
              <ion-label position="stacked" for="sharedItemCost">Cost:</ion-label>
              <ion-input inputmode="number" name="sharedItemCost" type="number" placeholder="10" v-model="item.cost">$&nbsp;</ion-input>
            </ion-item>
            <ion-item class="noborder">
              <ion-label style="font-weight: bold">Applies to:</ion-label>
            </ion-item>
            <ion-item v-for="participant in numParticipants" v-bind:key="participant">
              <ion-label>{{ participants[participant - 1].name }}</ion-label>
              <ion-checkbox slot="end" @ionChange="sharedItems[index].members[participant - 1] = $event.target.checked" :checked="sharedItems[index].members[participant - 1]"></ion-checkbox>
            </ion-item>

            <ion-button size="small" color="danger" style="margin: 10px" @click="deleteSharedItem(index)"><ion-icon class="delete-item" :icon="closeCircleOutline"></ion-icon> Delete Item</ion-button>
          </ion-card-content>
        </ion-card>


        <ion-button size="small" color="primary" style="margin: 10px" @click="addSharedItem()"><ion-icon class="delete-item" :icon="addCircleOutline"></ion-icon>New Shared Item</ion-button>

        <ion-chip class="participants-subtotal-chip" outline="false" color="primary">
          <ion-label
            >Shared Items Subtotal: <span class="participants-subtotal-chip-strong">$&nbsp;{{ sharedItemsSubtotal }}</span></ion-label
          >
        </ion-chip>
      </ion-card-content>
    </ion-card>
    <!-- --- -->
    <!-- STEP 3 -->
    <!-- --- -->
    <ion-card v-if="(participantsSubTotal + sharedItemsSubtotal) > 0">
      <ion-card-header>
        <ion-card-subtitle>Step 3</ion-card-subtitle>
        <ion-card-title>View the results</ion-card-title>
        <ion-card-subtitle style="font-style: italic">This section shows you a summary of the previous inputs sum, the corresponding fee for each participant, and the final total that he should be paying</ion-card-subtitle>
      </ion-card-header>
      <ion-card-content class="results-ion-card-content">
        <div style="padding-right: 15px; padding-left: 15px" v-if="(participantsSubTotal + sharedItemsSubtotal) > totalBilled">
          <ion-chip class="participants-subtotal-chip" style="height: 50px;" outline="false" color="danger">
            <ion-label> Error: The sum of individual and shared items' subtotal cannot be higher than the amount billed! </ion-label>
          </ion-chip>
        </div>
        <div v-if="(participantsSubTotal + sharedItemsSubtotal) <= totalBilled">
          <ion-list>
            <ion-item v-for="participant in numParticipants" v-bind:key="participant">
              <ion-avatar>
                <ion-icon class="participant-icon-result" :icon="accessibilityOutline"></ion-icon>
              </ion-avatar>
              <ion-label>
                <h2 style="font-weight: bold; font-size: 1.1em">{{ participants[participant - 1].name }}</h2>
                <h3>Personal: $ {{ Number(participants[participant - 1].subtotal) }}</h3>
                <h3>Shared: $ {{ participantsSharedSubtotal[participant - 1] }}</h3>
                <h3>Fees: $ {{ participantsProportionalFees[participant - 1] }}</h3>
                <p v-if="participants[participant - 1].comment">{{ participants[participant - 1].comment }}</p>
              </ion-label>
              <ion-badge color="none" slot="end" style="--padding-end: 0px; --padding-start: 0px">
                <div class="result-badge">
                  <ion-chip class="participant-subtotal-chip" outline="true" color="primary">
                    <ion-label
                      >Total: <span class="participant-subtotal-chip-strong">$&nbsp;{{ round( Number(participants[participant - 1].subtotal) + Number(participantsProportionalFees[participant - 1]) + Number(participantsSharedSubtotal[participant - 1]) ) }}</span></ion-label
                    >
                  </ion-chip>
                </div>
              </ion-badge>
            </ion-item>
          </ion-list>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import { IonAvatar, IonBadge, IonList, alertController, IonButton, IonChip, IonCard, IonCardContent, IonCheckbox, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonItem, IonLabel, IonInput, IonSegment, IonSegmentButton } from "@ionic/vue";
import { addCircleOutline, closeCircleOutline, pin, walk, warning, wifi, wine, close, personAddOutline, personRemoveOutline, personOutline, accessibilityOutline, createOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default {
  name: "SplitContainer",
  components: { IonAvatar, IonBadge, IonList, IonButton, IonChip, IonCard, IonCardContent, IonCheckbox, IonIcon, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonInput, IonLabel, IonSegment, IonSegmentButton },
  data() {
    return {
      totalPaid: null,
      tipIsIncluded: true,
      tipType: "percentage",
      tipPercentage: 15,
      tipAmount: null,
      numParticipants: 2,
      participants: [
        { id: 1, name: "Participant 1", subtotal: null, comment: "" },
        { id: 2, name: "Participant 2", subtotal: null, comment: "" },
      ],
      sharedItems: [
        // { name: "Pizza B", cost: 10.0, members: [false, false] },
      ],
      testB: false,
      pin,
      walk,
      warning,
      wifi,
      wine,
      close,
      personAddOutline,
      personRemoveOutline,
      personOutline,
      accessibilityOutline,
      createOutline,
      closeCircleOutline,
      addCircleOutline,
    };
  },
  computed: {
    totalBilled: function () {
      return this.round(Number(this.totalPaid) + Number(this.tip));
    },
    calculatedPercentage: function () {
      return this.round(Number(this.tipAmount / this.totalPaid) * 100);
    },
    tip: function () {
      if (this.tipIsIncluded === false && this.tipType === "percentage") {
        return this.round(this.totalPaid * (this.tipPercentage / 100));
      } else if (this.tipIsIncluded === false && this.tipType === "amount") {
        return this.tipAmount;
      } else {
        return 0;
      }
    },
    participantsSubTotal: function () {
      // return this.participants.reduce((subtotal, next) => (subtotal += Number(next.subtotal)), 0);
      let total = 0;
      for (let i = 0; i < this.numParticipants; i++) total += Number(this.participants[i].subtotal);
      return this.round(total);
    },
    participantsSharedSubtotal: function () {
      const result = [];
      for (let participantId = 0; participantId < this.numParticipants; participantId++) {
        let participantSharedSubtotal = 0;
        for (let itemId = 0; itemId < this.sharedItems.length; itemId++) {
          if (this.sharedItems[itemId].members[participantId]) {
            let itemCount = 0;
            for (let i = 0; i < this.numParticipants; i++) {
              if (this.sharedItems[itemId].members[i]) {
                itemCount++;
              }
            }
            participantSharedSubtotal+= this.sharedItems[itemId].cost/itemCount;
          }
          // participantSharedSubtotal += itemCount;
        }
        result.push(this.round(participantSharedSubtotal));
      }
      return result;
    },
    sharedItemsSubtotal: function () {
      return this.round(this.sharedItems.reduce((ac, value) => ac + Number(value.cost), 0));
    },
    participantsProportionalFees: function () {
      const difference = this.totalBilled - (this.participantsSubTotal + this.sharedItemsSubtotal);
      const result = [];
      for (let i = 0; i < this.numParticipants; i++) {
        const proportion = (Number(this.participants[i].subtotal) + this.participantsSharedSubtotal[i]) / (this.participantsSubTotal + this.sharedItemsSubtotal);
        result.push(this.round(difference * proportion));
      }
      return result;
    },
  },
  methods: {
    round(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },
    tipTypeChanged(ev) {
      this.tipType = ev.detail.value;
    },
    async participantNameClick(participantId) {
      console.log(participantId);
      const alert = await alertController.create({
        cssClass: "alertFieldCustom",
        header: "Edit Participant Info",
        inputs: [
          {
            value: "Participant Name",
            attributes: {
              disabled: "true",
            },
            cssClass: "participantIonAlertTitle",
          },
          {
            name: "participantName",
            id: "participantName",
            value: this.participants[participantId].name == `Participant ${participantId + 1}` ? null : this.participants[participantId].name,
            cssClass: "participantIonAlertInput",
            placeholder: this.participants[participantId].name,
          },
          {
            value: "Optional Comment",
            attributes: {
              disabled: "true",
            },
            cssClass: "participantIonAlertTitle",
          },
          {
            name: "participantComment",
            id: "participantComment",
            value: this.participants[participantId].comment,
            cssClass: "participantIonAlertInput",
            placeholder: "French Fries, Burger...",
            attributes: {
              "clear-input": "true",
            },
          },
        ],
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Ok",
            handler: (data) => {
              if (data.participantName) this.participants[participantId].name = data.participantName;
              if (data.participantComment) this.participants[participantId].comment = data.participantComment;
            },
          },
        ],
      });
      return alert.present();
    },
    participantSubtotalChange(evt) {
      console.log(evt);
    },
    deleteSharedItem(index) {
      this.sharedItems.splice(index, 1);
    },
    addSharedItem() {
      this.sharedItems.push({ name: "", cost: null, members: [] });
    },
  },
  watch: {
    numParticipants: function (newNum, oldNum) {
      if (newNum > oldNum && newNum > this.participants.length) {
        this.participants.push({ id: newNum, name: `Participant ${newNum}`, subtotal: null, comment: "" });
      }
      console.log(this.numParticipants);
    },
  },
  // props: {
  //   name: String
  // }
};
</script>

<style scoped>
ion-chip {
  pointer-events: none;
}

/* ion-card{ */
/* min-width: 200px; */
/* width: 600px; */
/* width: 100%; */
/* right: 0px; */
/* max-width: 800px;
  left: 400px; */
/* } */

.results-ion-card-content {
  padding-left: 0px;
  padding-right: 0px;
}
.result-badge {
  /* color: blue; */
  font-weight: 0;
}
.participant-edit-icon {
  color: blue;
  top: 3px;
  font-size: 1.2em;
  margin-left: 5px;
  position: relative;
}
.add-remove-participant-button {
  --ion-color-base: white !important;
}
.add-remove-participant-button-icon {
  color: black !important;
}
.add-remove-participant-button-input {
  color: black !important;
  left: 0px;
  text-align: right;
  width: 100px;
  font-size: 1.2em;
}
.participant-subtitle {
  color: black;
  font-size: 1.1em;
}
.participant-icon {
  margin-right: 10px;
  font-size: 1.2em;
  top: 3px;
  position: relative;
  color: blue;
}
.participant-icon-result {
  color: blue;
  font-size: 1.6em;
  top: 10px;
  position: relative;
  /* margin-right: 40px; */
}
.participant-comment {
  margin-top: -15px !important;
  margin-bottom: 8px !important;
  margin-left: 0px !important;
  font-size: 1.1em !important;
  font-style: italic !important;
}

.participant-subtotal-chip {
  vertical-align: middle;
  line-height: 10;
  margin-top: 10px;
  margin-bottom: -1px;
  position: relative;
  font-size: 1em;
  font-weight: 400 !important;
}

.participant-subtotal-chip-strong {
  font-weight: bold;
}
.participants-subtotal-chip-strong {
  font-weight: bold;
}

.tip-panel {
  margin-top: 10px;
  margin-left: 10px;
  width: 70%;
  max-width: 80%;
  position: relative;
}
.total-billed {
  margin-top: 15px;
  /* font-size: 1.2em; */
  line-height: 10;
}
.total-billed-strong {
  font-weight: bold;
}
.tipResult {
  /* --highlight-background: 0 !important;
  --border-width: 0px; */
  margin-top: 0px;
  font-weight: 500;
  font-style: italic;
  color: gray;
  /* font-size: 1.4em; */
}
.participantsSelector {
  display: grid;
  grid-auto-flow: column;
  margin-top: 5px;
  /* display: inline-block; */
  /* width: 10px; */
}
.participantsSelector ion-button {
  width: 50px;
}
.participantsSelector ion-input {
  width: 100px;
  text-align: center;
}
.noborder {
  border: 0px;
  --inner-border-width: 0px 0px 0px 0px !important;
  --highlight-background: 0 !important;
  --border-width: 0px !important;
}
.align-right {
  text-align: right;
}
/* .alertFieldTitle .alertWrapper{
  color: black;
  border: 0px;
  font-size: 1.2em;
  --highlight-background: 0 !important;
  --border-width: 0px !important;
}
.alertFieldInput .alertWrapper{
  color: gray;
  font-size: 1.4em;
} */

#container {
  margin: 0px;
  padding: 0px;
  left: 0;
  right: 0;

  /* top: 130px; */
  /* transform: translateY(-50%); */
  /* margin: 20px; */
  /* position: absolute; */
}

/* #container{
  position: absolute;
  margin: 0px;
  padding: 0px;
  right: 0;
  left: 0;

  left: 50%;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
} */

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container ion-card {
  /* background-color: red; */
  margin: 0px 0px 15px 0px;
}

/* #container p {
  font-size: 16px;
  line-height: 32px;
  color: #8c8c8c;
  margin: 0;
} */

#container a {
  text-decoration: none;
}

.delete-item {
  margin-right: 10px;
}
</style>