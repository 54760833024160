<template>
  <!-- <ion-tab tab="split"> -->
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-title>Banatu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content :fullscreen="true" class="ion-padding">
        <ion-grid fixed>
          <SplitContainer />
        </ion-grid>
      </ion-content>
    </ion-page>
  <!-- </ion-tab> -->
</template>

<script>
import { IonGrid, IonPage, IonContent, IonTitle, IonHeader, IonToolbar } from "@ionic/vue";
import SplitContainer from "@/components/SplitContainer.vue";

export default {
  name: "Split",
  components: {
    SplitContainer,
    IonContent,
    IonPage,
    IonTitle,
    IonHeader,
    IonToolbar,
    IonGrid
  },
  computed: {
    tabTitle: function () {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
ion-title {
  text-align: center;
}
</style>